<template>
  <!-- Start Our Team -->
  <section class="team" id="team">
    <div class="container">
      <div class="main-title text-center">
        <span class="separator">
          <i class="flaticon-chakra"></i>
        </span>
        <h2>Our Awesome Team</h2>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="member">
            <div class="member-img">
              <img src="https://via.placeholder.com/359x400" class="img-fluid" alt="" />
              <div class="overlay">
                <div class="social-media text-center">
                  <a href="https://themeforest.net/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                  <a href="https://themeforest.net/" target="_blank"><i class="fab fa-twitter"></i></a>
                  <a href="https://themeforest.net/" target="_blank"><i class="fab fa-instagram"></i></a>
                  <a href="https://themeforest.net/" target="_blank"><i class="fab fa-dribbble"></i></a>
                </div>
              </div>
            </div>
            <div class="member-info">
              <h4 class="member-name">Razan Smith</h4>
              <span>Yoga Teacher</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="member">
            <div class="member-img">
              <img src="https://via.placeholder.com/359x400" class="img-fluid" alt="" />
              <div class="overlay">
                <div class="social-media text-center">
                  <a href="https://themeforest.net/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                  <a href="https://themeforest.net/" target="_blank"><i class="fab fa-twitter"></i></a>
                  <a href="https://themeforest.net/" target="_blank"><i class="fab fa-instagram"></i></a>
                  <a href="https://themeforest.net/" target="_blank"><i class="fab fa-dribbble"></i></a>
                </div>
              </div>
            </div>
            <div class="member-info">
              <h4 class="member-name">Elina Ekman</h4>
              <span>Yoga Teacher</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 offset-lg-0 col-md-6 offset-md-3">
          <div class="member">
            <div class="member-img">
              <img src="https://via.placeholder.com/359x400" class="img-fluid" alt="" />
              <div class="overlay">
                <div class="social-media text-center">
                  <a href="https://themeforest.net/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                  <a href="https://themeforest.net/" target="_blank"><i class="fab fa-twitter"></i></a>
                  <a href="https://themeforest.net/" target="_blank"><i class="fab fa-instagram"></i></a>
                  <a href="https://themeforest.net/" target="_blank"><i class="fab fa-dribbble"></i></a>
                </div>
              </div>
            </div>
            <div class="member-info">
              <h4 class="member-name">Peter Doe</h4>
              <span>Yoga Teacher</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Our Team -->
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>